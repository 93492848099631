<template>
  <div>
    <!-- profile -->
    <b-overlay :show="show" rounded="sm">
      <template #overlay>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <b-spinner variant="primary" label="Spinning" />
          <p class="mt-1">{{ $t("Fetching Data") }}...</p>
        </div>
      </template>
      <b-card
        :key="refreshProfile"
        class="mb-2"
        style="
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
          border-radius: 20px;
          height: auto;
          overflow-y: auto;
        "
      >
        <b-container class="bv-example-row">
          <b-row>
            <!-- column 1 -->
            <b-col cols="3" class="d-flex align-items-center">
              <b-container class="bv-example-row">
                <b-row>
                  <b-col
                    class="d-flex justify-content-center"
                    style="position: relative"
                  >
                    <i
                    v-if="id == undefined"
                     v-b-tooltip.hover.bottom="$t('Change Profile Picture')"
                      class="fa-solid fa-camera-retro fa-xl"
                      style="
                        position: absolute;
                        z-index: 10;
                        bottom: 10px;
                        right: 40px;
                        cursor: pointer;
                      "
                      @click="changeProfilePic"
                    ></i>
                    <input
                      type="file"
                      ref="fileInput"
                      accept="image/jpeg, image/png"
                      @change="handleFileChange"
                      style="display: none"
                    />
                    <img
                      class="imageProfile"
                      :src="
                        profile.image === null
                          ? require(`@/assets/images/user.png`)
                          : profile.image.url
                      "
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <!-- column 2 -->
            <b-col cols="9">
              <b-row class="saj-title m-0 mb-1 mt-1 p-0">
                <p class="ellipsisProfile" style="font-weight: 600">
                  {{ profile.full_name }}
                </p>
              </b-row>
              <b-row>
                <b-col md="4">
                  <p class="saj-text d-flex">
                    <feather-icon
                      v-b-tooltip.hover.bottom="$t('Email')"
                      class="mr-1"
                      icon="MailIcon"
                      size="22"
                      stroke="black"
                    />
                    <span class="ellipsisProfile">
                      {{ profile.email }}
                    </span>
                  </p></b-col
                >
                <b-col md="4">
                  <p class="saj-text d-flex">
                    <feather-icon
                      v-b-tooltip.hover.bottom="$t('Position')"
                      class="mr-1"
                      icon="UserIcon"
                      size="22"
                      stroke="black"
                    />
                    <span class="ellipsisProfile">
                      {{ position }}
                    </span>
                  </p></b-col
                >
                <b-col md="4">
                  <p class="saj-text d-flex">
                    <feather-icon
                      v-b-tooltip.hover.bottom="$t('Phone Number')"
                      class="mr-1"
                      icon="PhoneIcon"
                      size="22"
                      stroke="black"
                    />
                    <span class="ellipsisProfile"> {{ mobile_number }} </span>
                  </p></b-col
                >
                <b-col md="4">
                  <p class="saj-text d-flex">
                    <feather-icon
                      v-b-tooltip.hover.bottom="$t('Employee Id')"
                      class="mr-1"
                      icon="ClipboardIcon"
                      size="22"
                      stroke="black"
                    />
                    <span class="ellipsisProfile"> {{ employee_number }} </span>
                  </p></b-col
                >

                <b-col md="4">
                  <p class="saj-text d-flex">
                    <img
                      v-b-tooltip.hover.bottom="$t('Department')"
                      class="mr-1"
                      src="@/assets/images/icons/department.png"
                      width="23px"
                      height="23px"
                    />
                    <span class="ellipsisProfile">
                      {{ department }}
                    </span>
                  </p></b-col
                >
                <b-col v-if="!show" md="4">
                  <p class="saj-text d-flex">
                    <feather-icon
                      v-b-tooltip.hover.bottom="$t('Supervisor')"
                      class="mr-1"
                      icon="UsersIcon"
                      size="22"
                      stroke="black"
                    />
                    <span class="ellipsisProfile"> {{ supervisorName }} </span>
                  </p>
                </b-col>
                <b-col md="4">
                  <p class="saj-text d-flex">
                    <feather-icon
                      v-b-tooltip.hover.bottom="$t('Hire Date')"
                      class="mr-1"
                      icon="CalendarIcon"
                      size="22"
                      stroke="black"
                    />
                    <span class="ellipsisProfile">
                      {{ $t(DateChanged(hire_date)) }}
                    </span>
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </b-card>

      <!-- detail -->

      <section id="card-navigation">
        <b-row>
          <!-- pill tabs inside card -->

          <b-col md="12">
            <b-card
              :key="refreshPersonal"
              class="text-center"
              style="
                background: #ffffff;
                box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                border-radius: 20px;
                height: auto;
                overflow-y: auto;
              "
            >
              <b-tabs nav-class="saj-title" style="">
                <b-tab :title="$t('Personal Details')" active>
                  <b-container class="bv-example-row">
                    <b-row
                      v-if="currentUserRole === 1"
                      style="display: flex; justify-content: flex-end"
                    >
                      <b-button
                        style="
                          background: #0b103c !important;
                          border-color: #0b103c !important;
                        "
                        class="saj-button mr-3"
                        @click="createUser()"
                      >
                        {{ $t("Save") }}
                      </b-button>
                    </b-row>
                    <b-row>
                      <!-- Basic details -->
                      <b-col class="">
                        <b-container class="bv-example-row">
                          <b-row
                            class="saj-title d-flex justify-content-start m-0 mb-2"
                          >
                            {{ $t("Basic Details") }}
                          </b-row>

                          <validation-observer ref="basicRules">
                            <b-row class="">
                              <b-col class="">
                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- First name -->
                                    <span
                                      class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                      >{{ $t("First Name") }}</span
                                    >
                                    <b-col cols="10">
                                      <b-form-group label-for="defaultLabel">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('First Name')"
                                          rules="required"
                                        >
                                          <b-form-input
                                            v-model="profile.first_name"
                                            :disabled="isDisabled"
                                            class="saj-text m-0"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                        <!-- <b-form-input
                                        v-model="profile.first_name"
                                        :disabled="isDisabled"
                                        class="saj-text m-0"
                                      /> -->
                                      </b-form-group>
                                    </b-col>
                                  </b-col>
                                </b-row>

                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- Fullname -->
                                    <span
                                      class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                      >{{ $t("Middle Name") }}</span
                                    >
                                    <b-col cols="10">
                                      <b-form-group label-for="defaultLabel">
                                        <!-- <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Middle Name')"
                                          rules="required"
                                        > -->
                                        <b-form-input
                                          v-model="profile.middle_name"
                                          :disabled="isDisabled"
                                          class="saj-text m-0"
                                        />
                                        <!-- <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider> -->
                                      </b-form-group>
                                    </b-col>
                                  </b-col>
                                </b-row>

                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- Fullname -->
                                    <span
                                      class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                      >{{ $t("Last Name") }}</span
                                    >
                                    <b-col cols="10">
                                      <b-form-group label-for="defaultLabel">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Last Name')"
                                          rules="required"
                                        >
                                          <b-form-input
                                            v-model="profile.last_name"
                                            :disabled="isDisabled"
                                            class="saj-text m-0"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                  </b-col>
                                </b-row>

                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- Fullname -->
                                    <span
                                      class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                      >{{ $t("Full Name") }}</span
                                    >
                                    <b-col cols="10">
                                      <b-form-group label-for="defaultLabel">
                                        <b-form-input
                                          id="namapenuh"
                                          v-model="profile.full_name"
                                          class="saj-text m-0"
                                          disabled
                                        />
                                      </b-form-group> </b-col
                                  ></b-col>
                                </b-row>

                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- Phone Number -->
                                    <span
                                      class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                      >{{ $t("Phone Number") }}</span
                                    >
                                    <b-col cols="10">
                                      <b-form-group label-for="largeInput">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Phone Number')"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="nombortelefon"
                                            v-model="profile.mobile_number"
                                            :disabled="isDisabled"
                                            type="number"
                                            class="saj-text m-0"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col></b-col
                                  >
                                </b-row>

                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- Email -->
                                    <span
                                      class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                      >{{ $t("Email") }}</span
                                    >
                                  
                                    <b-col cols="10">
                                      <b-form-group label-for="largeInput">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Email')"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="email"
                                            v-model="profile.email"
                                            class="saj-text m-0"
                                            :disabled="isDisabled"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col></b-col
                                  >
                                </b-row>

                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- IC -->
                                    <span
                                      class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                      >{{ $t("Identity Card") }}</span
                                    >
                                    <b-col cols="10">
                                      <b-form-group label-for="largeInput">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Identity Card')"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="ic"
                                            v-model="profile.ic"
                                            :disabled="isDisabled"
                                            :placeholder="
                                              $t('Eg: xxxxxxxxxxxx')
                                            "
                                            type="number"
                                            class="saj-text m-0"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col></b-col
                                  >
                                </b-row>

                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- Dob -->
                                    <span
                                      class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                      >{{ $t("Date of Birth") }}</span
                                    >
                                    <b-col cols="10">
                                      <b-form-group>
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Date of Birth')"
                                          rules="required"
                                        >
                                          <flat-pickr
                                            v-model="profile.dob"
                                            class="saj-text form-control m-0 bg-light"
                                            style="background: #f2f2f2"
                                            :config="{
                                              dateFormat: 'Y-m-d',
                                              altInput: true,
                                              altFormat: 'd-m-Y',
                                            }"
                                            disabled
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col></b-col
                                  >
                                </b-row>

                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- Gender -->
                                    <span
                                      class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                      >{{ $t("Gender") }}</span
                                    >
                                    <b-col cols="10">
                                      <validation-provider
                                        #default="{ errors }"
                                        :name="$t('Gender')"
                                        rules="required"
                                      >
                                        <b-form-select
                                          v-model="profile.gender"
                                          :options="genderoptions"
                                          :disabled="isDisabled"
                                          class="saj-text m-0 mb-1"
                                        />
                                        <small class="text-danger">{{
                                          errors[0]
                                        }}</small>
                                      </validation-provider>
                                    </b-col></b-col
                                  >
                                </b-row>

                                <b-row>
                                  <b-col class="p-0"
                                    ><!-- dob -->
                                    <span
                                      class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                      >{{ $t("Religion") }}</span
                                    >
                                    <b-col cols="10">
                                      <b-form-group label-for="largeInput">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Religion')"
                                          rules="required"
                                        >
                                          <b-form-input
                                            id="agama"
                                            v-model="profile.religion"
                                            :disabled="isDisabled"
                                            class="saj-text m-0"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col></b-col
                                  >
                                </b-row>
                              </b-col>

                              <!-- Personal Address -->
                              <b-col style="" class="">
                                <b-container class="bv-example-row">
                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Location -->
                                      <span
                                        class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                        >{{ $t("Race") }}</span
                                      >
                                      <b-col cols="10">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Race')"
                                          rules="required"
                                        >
                                          <b-form-select
                                            v-model="profile.race"
                                            :options="raceoptions"
                                            :disabled="isDisabled"
                                            class="saj-text"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-col></b-col
                                    >
                                  </b-row>

                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Address -->
                                      <span
                                        class="saj-subtitle m-0 ml-1 mt-1 d-flex justify-content-start"
                                        >{{ $t("Citizenship") }}</span
                                      >
                                      <b-col cols="10">
                                        <b-form-group label-for="defaultLabel">
                                          <validation-provider
                                            #default="{ errors }"
                                            :name="$t('Citizenship')"
                                            rules="required"
                                          >
                                            <b-form-input
                                              id="nationality"
                                              v-model="profile.nationality"
                                              :disabled="isDisabled"
                                              class="saj-text"
                                            />
                                            <small class="text-danger">{{
                                              errors[0]
                                            }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col></b-col
                                    >
                                  </b-row>

                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Marital -->
                                      <span
                                        class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                        >{{ $t("Marital Status") }}</span
                                      >
                                      <b-col cols="10">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('Marital Status')"
                                          rules="required"
                                        >
                                          <b-form-select
                                            v-model="profile.marital_status"
                                            :options="marital_statusoptions"
                                            :disabled="isDisabled"
                                            class="saj-text mb-1"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-col></b-col
                                    >
                                  </b-row>
                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Address -->
                                      <span
                                        class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                        >{{ $t("Address") }} 1</span
                                      >
                                      <b-col cols="10">
                                        <b-form-group label-for="defaultLabel">
                                          <validation-provider
                                            #default="{ errors }"
                                            :name="$t('Address') + 1"
                                            rules="required"
                                          >
                                            <b-form-input
                                              v-model="profile.address1"
                                              :disabled="isDisabled"
                                              class="saj-text"
                                            />
                                            <small class="text-danger">{{
                                              errors[0]
                                            }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Address -->
                                      <span
                                        class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                        >{{ $t("Address") }} 2</span
                                      >
                                      <b-col cols="10">
                                        <b-form-group label-for="defaultLabel">
                                            <b-form-input
                                              v-model="profile.address2"
                                              :disabled="isDisabled"
                                              class="saj-text"
                                            />
                                        </b-form-group>
                                      </b-col>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Address -->
                                      <span
                                        class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                        >{{ $t("Address") }} 3</span
                                      >
                                      <b-col cols="10">
                                        <b-form-group label-for="defaultLabel">
                                            <b-form-input
                                              v-model="profile.address3"
                                              :disabled="isDisabled"
                                              class="saj-text"
                                            />
                                        </b-form-group>
                                      </b-col>
                                    </b-col>
                                  </b-row>

                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Postal code -->
                                      <span
                                        class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                        >{{ $t("Postal Code") }}</span
                                      >
                                      <b-col cols="5">
                                        <b-form-group label-for="largeInput">
                                          <validation-provider
                                            #default="{ errors }"
                                            :name="$t('Postal Code')"
                                            rules="required"
                                          >
                                            <b-form-input
                                              id="largeInput"
                                              v-model="profile.postal_code"
                                              :disabled="isDisabled"
                                              type="number"
                                              class="saj-text"
                                            />
                                            <small class="text-danger">{{
                                              errors[0]
                                            }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col></b-col
                                    >
                                  </b-row>

                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Postal code -->
                                      <span
                                        class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                        >{{ $t("City") }}</span
                                      >
                                      <b-col cols="10">
                                        <b-form-group label-for="largeInput">
                                          <validation-provider
                                            #default="{ errors }"
                                            :name="$t('City')"
                                            rules="required"
                                          >
                                            <b-form-input
                                              id="city"
                                              v-model="profile.city"
                                              :disabled="isDisabled"
                                              style=""
                                              class="saj-text"
                                            />
                                            <small class="text-danger">{{
                                              errors[0]
                                            }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col></b-col
                                    >
                                  </b-row>

                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Location -->
                                      <span
                                        class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                        >{{ $t("State") }}</span
                                      >
                                      <b-col cols="10">
                                        <validation-provider
                                          #default="{ errors }"
                                          :name="$t('State')"
                                          rules="required"
                                        >
                                          <select
                                            v-model="profile.state"
                                            :disabled="isDisabled"
                                            class="saj-text form-control m-0 mb-1"
                                            style=""
                                          >
                                            <option
                                              v-for="state in stateList"
                                              :key="state.name"
                                              :value="state.name"
                                            >
                                              {{ state.name }}
                                            </option>
                                          </select>
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-col></b-col
                                    >
                                  </b-row>

                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Postal code -->
                                      <span
                                        class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                        >{{ $t("Country") }}</span
                                      >
                                      <b-col cols="10">
                                        <b-form-group label-for="largeInput">
                                          <validation-provider
                                            #default="{ errors }"
                                            :name="$t('Country')"
                                            rules="required"
                                          >
                                            <b-form-input
                                              id="country"
                                              v-model="profile.country"
                                              :disabled="isDisabled"
                                              style=""
                                              class="saj-text"
                                            />
                                            <small class="text-danger">{{
                                              errors[0]
                                            }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col></b-col
                                    >
                                  </b-row>

                                  <b-row>
                                    <b-col class="p-0"
                                      ><!-- Postal code -->
                                      <span
                                        class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                        >{{ $t("Address") }}</span
                                      >
                                      <b-col cols="10">
                                        <b-form-group label-for="largeInput">
                                          <b-form-textarea
                                            id="alamat"
                                            v-model="profile.address"
                                            disabled
                                            style=""
                                            rows="8"
                                            class="saj-text"
                                          />
                                        </b-form-group> </b-col
                                    ></b-col>
                                  </b-row>
                                </b-container>
                              </b-col>
                            </b-row>
                          </validation-observer>
                        </b-container>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-tab>
                <b-tab :title="$t('Organization')">
                  <b-container class="bv-example-row">
                    <b-row
                      v-if="currentUserRole === 1"
                      style="display: flex; justify-content: flex-end"
                    >
                      <b-button
                        style="
                          background: #0b103c !important;
                          border-color: #0b103c !important;
                        "
                        class="saj-button mr-3"
                        @click="createOrganization()"
                      >
                        {{ $t("Save") }}
                      </b-button>
                    </b-row>
                    <b-row>
                      <!-- Work details -->
                      <b-col class="">
                        <b-container class="bv-example-row">
                          <b-row
                            class="saj-title d-flex justify-content-start m-0 mb-2"
                          >
                            {{ $t("Work Details") }}
                          </b-row>
                          <validation-observer ref="orgRules">
                            <b-row>
                              <b-col class="p-0"
                                ><!-- Designation -->
                                <span
                                  class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                  >{{ $t("Designation") }}</span
                                >
                                <b-col cols="10">
                                  <b-form-group label-for="defaultLabel">
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('Designation')"
                                      rules="required"
                                    >
                                      <!-- <b-form-input
                                    id="jawatan"
                                    v-model="profile.position"

                                    class="saj-text m-0"
                                    :disabled="isDisabled"
                                  /> -->
                                      <b-form-select
                                        v-model="profile.position"
                                        :options="positionList"
                                        :value="positionList"
                                        :placeholder="$t('Select Position')"
                                        size="lg"
                                        class="saj-text"
                                        rows="8"
                                        disabled
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col class="p-0"
                                ><!-- Grade -->
                                <span
                                  class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                  >{{ $t("Grade") }}</span
                                >
                                <b-col cols="10">
                                  <b-form-group label-for="largeInput">
                                    <b-form-select
                                      v-model="profile.grade"
                                      :options="gradeList"
                                      :value="gradeList"
                                      size="lg"
                                      class="saj-text"
                                      rows="8"
                                      :disabled="isDisabled"
                                      @change="getGradeSet()"
                                    />
                                  </b-form-group> </b-col
                              ></b-col>
                            </b-row>

                            <b-row>
                              <b-col class="p-0"
                                ><!-- Grade Set -->
                                <span
                                  class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                  style="color: black"
                                  >{{ $t("Grade set") }}</span
                                >
                                <b-col cols="10">
                                  <b-form-group label-for="largeInput">
                                    <b-form-input
                                      id="gredset"
                                      v-model="profile.grade_set"
                                      :value="allGrade.value"
                                      class="saj-text m-0"
                                      disabled
                                    />
                                  </b-form-group> </b-col
                              ></b-col>
                            </b-row>

                            <b-row>
                              <b-col class="p-0"
                                ><!-- Emp Num -->
                                <span
                                  class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                  style="color: black"
                                  >{{ $t("Employee Id") }}</span
                                >
                                <b-col cols="10">
                                  <b-form-group label-for="largeInput">
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('Employee ID')"
                                      rules="required"
                                    >
                                      <b-form-input
                                        id="no.pekerja"
                                        v-model="profile.employee_number"
                                        class="saj-text m-0"
                                        :disabled="isDisabled"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col></b-col
                              >
                            </b-row>
                            <b-row>
                              <b-col class="p-0"
                                ><!-- Hire Date -->
                                <span
                                  class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                  style="color: black"
                                  >{{ $t("Hire Date") }}</span
                                >
                                <b-col cols="10">
                                  <b-form-group>
                                    <!-- <flat-pickr
                                  v-model="profile.hire_date"
                                  class="saj-text form-control m-0"
                                  style=""
                                  :style="roles.isHR ? `background-color: white !important` : `background-color: #efefef !important`"
                                  :disabled="!roles.isHR"
                                  :config="{ dateFormat: 'Y-m-d' , altInput: true, altFormat:'d-m-Y'}"
                                /> -->
                                    <flat-pickr
                                      v-model="profile.hire_date"
                                      class="saj-text form-control m-0 bg-light"
                                      style="background: #f2f2f2"
                                      disabled
                                      :config="{
                                        dateFormat: 'Y-m-d',
                                        altInput: true,
                                        altFormat: 'd-m-Y',
                                      }"
                                    />
                                  </b-form-group> </b-col
                              ></b-col>
                            </b-row>

                            <b-row>
                              <b-col class="p-0"
                                ><!-- Supervisor -->
                                <span
                                  class="m-0 ml-1 d-flex justify-content-start saj-subtitle"
                                  style="color: black"
                                  >{{ $t("Supervisor Id") }}</span
                                >
                                <b-col cols="10">
                                  <b-form-group label-for="largeInput">
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('Supervisor Id')"
                                      rules="required"
                                    >
                                      <v-select
                                        v-model="supervisor"
                                        class="saj-title select-size-lg"
                                        style="background: white"
                                        label="empNo"
                                        :options="svNameList"
                                        :value="svNameList"
                                        :disabled="isDisabled"
                                        @input="showValue(supervisor)"
                                      />

                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col></b-col
                              >
                            </b-row>
                            <b-row>
                              <b-col class="p-0"
                                ><!-- Sv name -->
                                <span
                                  class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                  style="color: black"
                                  >{{ $t("Supervisor Name") }}</span
                                >
                                <b-col cols="10">
                                  <!-- sv name: {{ supervisorName }} -->
                                  <b-form-group label-for="largeInput">
                                    <b-form-input
                                      v-model="supervisorName"
                                      disabled
                                      class="saj-text m-0"
                                      :placeholder="$t('Supervisor Name')"
                                    />
                                  </b-form-group> </b-col
                              ></b-col>
                            </b-row>
                          </validation-observer>
                        </b-container>
                      </b-col>
                      <b-col class="">
                        <b-container class="bv-example-row">
                          <b-row
                            class="saj-title d-flex justify-content-start m-0 mb-2"
                          >
                            {{ $t("Organization Details") }}
                          </b-row>
                          <b-row>
                            <b-col class="p-0"
                              ><!-- Branch -->
                              <span
                                class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                style="color: black"
                                >{{ $t("Work Location") }}</span
                              >
                              <b-col cols="10">
                                <b-form-group label-for="defaultLabel">
                                  <!-- <b-form-input
                                  v-model="branch"
                                  :value="profile.branch_id"
                                  class="saj-text m-0"
                                  :disabled="isDisabled"
                                /> -->
                                  <b-form-select
                                    v-model="profile.branch_id"
                                    :options="branchList"
                                    :value="branchList"
                                    :disabled="isDisabled"
                                    :placeholder="$t('Select Work Location')"
                                    size="lg"
                                    class="saj-text"
                                    rows="8"
                                  />
                                </b-form-group> </b-col
                            ></b-col>
                          </b-row>

                          <!-- District -->
                          <!-- <b-row>
                          <b-col
                            class="p-0"
                          >

                            <span
                              class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                              style="color: black"
                            >{{ $t('District') }}</span>
                            <b-col cols="10">
                              <b-form-group label-for="defaultLabel">
                                <b-form-input
                                  v-model="district"

                                  type="text"
                                  class="saj-text m-0"
                                  :disabled="isDisabled"
                                />
                              </b-form-group>
                            </b-col>
                          </b-col>
                        </b-row> -->
                          <b-row>
                            <b-col class="p-0"
                              ><!-- Department -->
                              <span
                                class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                style="color: black"
                                >{{ $t("Department") }}</span
                              >
                              <b-col cols="10">
                                <b-form-group label-for="defaultLabel">
                                  <!-- <b-form-input
                                  v-model="department"

                                  class="saj-text m-0"
                                  :disabled="isDisabled"
                                /> -->
                                  <b-form-select
                                    v-model="profile.department_id"
                                    :options="departmentList"
                                    :value="departmentList"
                                    :disabled="isDisabled"
                                    :placeholder="$t('Select Department')"
                                    size="lg"
                                    class="saj-text"
                                    rows="8"
                                  />
                                </b-form-group> </b-col
                            ></b-col>
                          </b-row>
                          <b-row>
                            <b-col class="p-0"
                              ><!-- Year of service -->
                              <span
                                class="saj-subtitle m-0 ml-1 d-flex justify-content-start"
                                style="color: black"
                                >{{ $t("Business unit") }}</span
                              >
                              <b-col cols="10">
                                <b-form-group label-for="largeInput">
                                  <!-- <b-form-input
                                  id="unitbisnes"
                                  v-model="profile.business_unit"
                                  type="text"

                                  class="saj-text"
                                  :disabled="isDisabled"
                                /> -->
                                  <b-form-select
                                    v-model="profile.business_unit"
                                    :options="businessUnitList"
                                    :value="businessUnitList"
                                    :disabled="isDisabled"
                                    :placeholder="$t('Select Business Unit')"
                                    size="lg"
                                    class="saj-text"
                                    rows="8"
                                  />
                                </b-form-group> </b-col
                            ></b-col>
                          </b-row>
                          <!-- Cont. Work Details -->
                        </b-container></b-col
                      ></b-row
                    >
                  </b-container>
                </b-tab>
                <b-tab :title="$t('Education')">
                  <b-col
                    v-if="roles.isAdmin || isHasEducation"
                    style="display: flex; justify-content: flex-end"
                  >
                    <b-button
                      style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                      class="saj-button mr-1"
                      @click="educationInfo()"
                    >
                      {{ $t("Save") }}
                    </b-button>
                  </b-col>

                  <div class="m-2">
                    <validation-observer ref="validateEditEducation">
                      <b-form
                        ref="form"
                        :style="{ height: trHeight }"
                        class="repeater-form"
                        @submit.prevent="repeateAgain"
                      >
                        <!-- Row Loop -->
                        <div v-if="isEduInfo">
                          <b-row
                            v-for="(existitem, i) in existitems"
                            :id="existitem.id"
                            :key="'a' + i"
                            ref="row"
                          >
                            {{ existitem.user_id }}
                            <!-- eduid:{{ existitem.eduId }} -->
                            <!-- Kelayakan -->
                            <b-col md="3">
                              <b-form-group
                                :label="$t('Qualification')"
                                class="saj-subtitle"
                              >
                                <b-form-select
                                  v-model="existitem.qualification"
                                  class="saj-text"
                                  :options="
                                    isEnglish ? quaoptions_bi : quaoptions
                                  "
                                  :disabled="isDisabled"
                                />
                              </b-form-group>
                            </b-col>

                            <!-- Institusi -->
                            <b-col md="3">
                              <b-form-group
                                :label="$t('Institute')"
                                class="saj-subtitle"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Institute')"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="existitem.institute"
                                    class="saj-text"
                                    type="text"
                                    :disabled="isDisabled"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>

                            <!-- Bidang Pengajian -->
                            <b-col md="3">
                              <b-form-group
                                :label="$t('Field of study')"
                                class="saj-subtitle"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Field of study')"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="existitem.field_of_study"
                                    class="saj-text"
                                    type="text"
                                    :disabled="isDisabled"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>

                            <!-- Tahun Graduan -->
                            <b-col md="2">
                              <b-form-group
                                :label="$t('Year of Graduation')"
                                class="saj-subtitle"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Year of Graduation')"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="existitem.year_of_graduate"
                                    class="saj-text mb-2"
                                    type="number"
                                    :disabled="isDisabled"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <!-- delete education -->
                            <b-col v-if="roles.isAdmin">
                              <feather-icon
                                style="
                                  width: 30px;
                                  height: 30px;
                                  color: #c5c7cd;
                                  margin-top: 30px;
                                "
                                icon="MinusCircleIcon"
                                @click="
                                  showAlertModal(
                                    'edu-task',
                                    (val = existitem.eduId)
                                  )
                                "
                              />
                            </b-col>
                            <!-- display attachment -->
                            <b-row
                              class="d-flex justify-content-start align-items-center m-0 ml-3 saj-subtitle"
                              style="color: #000000; width: 100%"
                            >
                              <div
                                v-if="existitem.file === null"
                                class="row align-items-center"
                                style="font-size: 0.8rem"
                              >
                                <feather-icon
                                  class="mr-1"
                                  icon="PaperclipIcon"
                                  size="16"
                                />
                                {{ $t("No File") }}
                              </div>
                              <div
                                v-if="existitem.file !== null"
                                class="row align-items-center"
                                style="font-size: 0.8rem"
                              >
                                <feather-icon
                                  class="mr-1"
                                  icon="PaperclipIcon"
                                  size="16"
                                />
                                <a :href="existitem.file.url" target="_blank">
                                  {{ existitem.file.name }}
                                </a>
                              </div>
                            </b-row>

                            <b-col cols="12">
                              <hr />
                            </b-col>
                          </b-row>
                        </div>
                        <div
                          v-else
                          class="saj-subtitle"
                          style="font-style: italic"
                        >
                          <div
                            v-if="
                              roles.isHR ||
                              roles.isHOD ||
                              roles.isTopManagement ||
                              roles.isExecutive
                            "
                          >
                            {{ $t("No Education Record Retrieved") }}
                          </div>
                        </div>
                      </b-form>
                    </validation-observer>
                    <b-modal
                      id="delete-edu"
                      :hide-footer="true"
                      :hide-header="true"
                      :centered="true"
                    >
                      <alert-modal
                        :title="$t('Click Yes to delete Education')"
                        confirm-btn="Yes"
                        @cancel="hideAlertModal('edu-task')"
                        @confirm="deleteEducation(deleteIdEdu)"
                      />
                    </b-modal>
                  </div>
                  <!-- Add New Education -->
                  <div v-if="roles.isAdmin" class="m-2">
                    <validation-observer ref="validateEducation">
                      <b-form
                        ref="form"
                        :style="{ height: trHeight }"
                        class="repeater-form"
                        @submit.prevent="repeateAgain"
                      >
                        <!-- Row Loop -->
                        <div v-for="item in items" :id="item.id" :key="item.id">
                          <b-row class="saj-subtitle">
                            <!-- Kelayakan -->
                            <b-col md="3">
                              <b-form-group
                                :label="$t('Qualification')"
                                class=""
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="Qualification "
                                  rules="required"
                                >
                                  <b-form-select
                                    v-model="item.qualification"
                                    class="saj-text"
                                    :options="
                                      isEnglish ? quaoptions_bi : quaoptions
                                    "
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>

                            <!-- Institusi -->
                            <b-col md="3">
                              <b-form-group :label="$t('Institute')" class="">
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Institute')"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="item.institute"
                                    class="saj-text"
                                    type="text"
                                    :placeholder="$t('Enter institute')"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>

                            <!-- Bidang Pengajian -->
                            <b-col md="3">
                              <b-form-group
                                :label="$t('Field of study')"
                                class=""
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Field of study')"
                                  rules="required"
                                >
                                  <b-form-input
                                    v-model="item.field_of_study"
                                    :placeholder="$t('Enter field of study')"
                                    class="saj-text"
                                    type="text"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>

                            <!-- Tahun Graduan -->
                            <b-col md="3">
                              <b-form-group
                                :label="$t('Year of Graduation')"
                                class=""
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Year of Graduation')"
                                  rules="required"
                                >
                                  <!-- {{ item.year_of_graduate }} -->
                                  <Datepicker
                                    id="input-id"
                                    v-model="item.year_of_graduate"
                                    input-class="my-picker-class"
                                    :value="defaultDate"
                                    :format="DatePickerFormat"
                                    type="number"
                                    minimum-view="year"
                                    name="datepicker"
                                    :typeable="true"
                                    class="saj-text"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>

                            <!-- File -->
                            <b-col md="3">
                              <validation-provider
                                #default="{ errors }"
                                :name="$t('File')"
                                rules="required"
                              >
                                <b-form-file
                                  ref="fileValidation"
                                  v-model="item.fileToUpload"
                                  type="file"
                                  class="mt-1 saj-text"
                                  :browse-text="$t('Choose File')"
                                  :placeholder="$t('No file choosen')"
                                  style="width: 100%; text-align: start"
                                  @change="onFileChangeEdu"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-col>
                            <!-- File end -->
                          </b-row>
                          <b-col cols="12">
                            <hr />
                          </b-col>
                        </div>
                      </b-form>
                    </validation-observer>
                  </div>
                </b-tab>
                <b-tab :title="$t('Employment History')">
                  <b-overlay :show="show" rounded="sm">
                    <template #overlay>
                      <div
                        class="d-flex flex-column align-items-center justify-content-center"
                      >
                        <b-spinner variant="primary" label="Spinning" />
                        <p>{{ $t("Fetching Data") }}...</p>
                      </div>
                    </template>
                    <div class="row p-2">
                      <b-table
                        class="styleTable mt-1"
                        style=""
                        :items="historyList"
                        :fields="fields"
                        :per-page="perPage"
                        :current-page="currentPage"
                        label-size="lg"
                        bordered
                        show-empty
                        responsive
                      >
                        <template #head()="data">
                          <span
                            class="saj-text d-flex justify-content-center"
                            >{{ $t(data.label) }}</span
                          >
                        </template>
                        <template #cell(index)="data">
                          <div class="saj-text d-flex justify-content-center">
                            {{
                              currentPage === 1
                                ? data.index + 1
                                : data.index + 1 + (currentPage - 1) * 10
                            }}
                          </div>
                        </template>
                        <template #empty="">
                          <h4 style="text-align: center; font-style: italic">
                            {{ $t("There are no records to show") }}
                          </h4>
                        </template>
                        <template #cell(date)="data">
                          <div class="saj-text" style="">
                            {{ DateChanged(data.item.date) }}
                          </div>
                        </template>
                        <template #cell(previous_subsidiary_name)="data">
                          <div class="saj-text" style="">
                            {{ data.item.previous_subsidiary_name }}
                          </div>
                        </template>
                        <template #cell(prev_department_name)="data">
                          <div class="saj-text" style="">
                            {{ data.item.prev_department_name }}
                          </div>
                        </template>
                        <template #cell(prev_position)="data">
                          <div class="saj-text" style="">
                            {{ data.item.prev_position }}
                          </div>
                        </template>
                        <template #cell(subsidiary_name)="data">
                          <div class="saj-text" style="">
                            {{ data.item.subsidiary_name }}
                          </div>
                        </template>
                        <template #cell(type)="data">
                          <div class="saj-text" style="">
                            {{ $t(data.item.type) }}
                          </div>
                        </template>
                        <template #cell(action)="data">
                          <div class="saj-text" style="">
                            <!-- {{ data.item.transfer_id }} -->
                            <div>
                              <feather-icon
                                v-b-modal.transfer-details
                                icon="EyeIcon"
                                color="purple"
                                class="hoverIcon"
                                size="25"
                                @click="transferID = data.item.transfer_id"
                              />
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                    <b-row v-if="historyList.length > 0">
                      <b-col class="d-flex justify-content-start">
                        <span class="saj-text">
                          {{ $t("Showing") }}
                          {{
                            rows > perPage * currentPage
                              ? perPage * currentPage
                              : rows
                          }}
                          {{ $t("from") }} {{ rows }} {{ $t("entries") }}
                          <!-- {{ $t("Showing") }} 1 {{ $t("to") }} 2 {{ $t("of") }} 3 {{ $t("entries") }} -->
                        </span>
                      </b-col>
                      <b-col>
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          pills
                          class="saj-text mb-1 justify-content-end"
                        />
                      </b-col>
                    </b-row>
                  </b-overlay>
                  <b-modal
                    id="transfer-details"
                    :hide-header="true"
                    :hide-footer="true"
                    :centered="true"
                    size="lg"
                  >
                    <!-- $bvModal.hide(nama modal id) untuk tutup modal-->
                    <emp-details
                      :transfer="transferID"
                      @close="$bvModal.hide('transfer-details')"
                      @transfer-details="$bvModal.hide('transfer-details')"
                    />
                  </b-modal>
                </b-tab>
                <!-- <b-tab title="Disable" disabled /> -->
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </b-overlay>
  </div>
</template>

<style scoped>
.hiddenFileInput > input {
  height: 100%;
  width: 90;
  opacity: 0;
  cursor: pointer;
}
.hiddenFileInput {
  /*display: flex;
  justify-content: center;
  padding-bottom: 30px; */
  display: inline-block;
  overflow: hidden;
  height: 60px;
  width: 150px;
  background: no-repeat;
  /* background-image:  url('~@/assets/images/button/muatnaik button.png'); */
}
</style>

<script>
import {
  BRow,
  BCol,
  BCard,
  BContainer,
  BTabs,
  BTab,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  // BFormDatepicker,
  BForm,
  BButton,
  BOverlay,
  BSpinner,
  VBTooltip,
  BTable,
  BPagination,
  BFormFile,
  // BAvatar,
} from "bootstrap-vue";
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
// import axios from "axios"
import flatPickr from "vue-flatpickr-component";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "@validations";
import SAJToast from "@/component/saj-toastification.vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import vSelect from "vue-select";
import { excluded } from "vee-validate/dist/rules";
import empDetails from "@/component/transfer-promotion/transfer&promotion-details.vue";
import AlertModal from "@/pages/alert-modal.vue";
// eslint-disable-next-line import/no-unresolved
const states = require("@/db/states.json");

extend("excluded", excluded);

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    BTabs,
    BTab,
    BFormInput,
    BFormGroup,
    BFormSelect,
    vSelect,
    // BFormDatepicker,
    BForm,
    BFormTextarea,
    flatPickr,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner,
    BTable,
    BPagination,
    empDetails,
    Datepicker,
    BFormFile,
    AlertModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],

  data() {
    return {
      test: [],
      show: true,
      id: this.$route.params.id,
      subId: this.$route.params.subId,
      name: "",
      role: "Non-Executive",
      email: "",
      position: "",
      mobile_number: "",
      employee_number: "",
      department: "",
      branch: "",
      district: "",
      supervisor: [],
      hire_date: "",
      stateList: states,
      currentUserRole: null,
      isEduInfo: true,
      required,
      refreshProfile: 0,
      supervisor_name: "",
      supervisorName: null,
      profile: {
        image: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        full_name: "",
        mobile_number: "",
        ic: "",
        dob: "",
        gender: null,
        religion: "",
        race: null,
        nationality: "",
        marital_status: "",
        address: "",
        address1: "",
        address2: "",
        address3: "",
        postal_code: "",
        city: "",
        country: "",
        position: "",
        grade: "",
        grade_set: "",
        employee_number: null,
        employee_status: null,
        hire_date: "",
        year_of_service: null,
        business_unit: "",
        department_id: "",
        // state: null,
      },
      isSetup: false,
      isNewLogo: false,
      newLogo: "",
      newLogoTemp: null,
      refreshPersonal: 0,
      staffList: [],
      existitems: [],
      items: [
        {
          qualification: "",
          institute: "",
          field_of_study: "",
          year_of_graduate: null,
        },
      ],
      genderoptions: [
        {
          value: null,
          text: `${this.$i18n.t(`Choose Gender`)}`,
          disabled: true,
        },
        { value: "Male", text: `${this.$i18n.t(`Male`)}` },
        { value: "Female", text: `${this.$i18n.t(`Female`)}` },
      ],
      quaoptions_bi: [
        { value: null, text: "Choose your Qualification", disabled: true },
        { value: "SPM", text: "SPM" },
        { value: "STPM", text: "STPM" },
        { value: "Skills Cert", text: "Skills Cert" },
        { value: "Diploma", text: "Diploma" },
        { value: "Bachelor's Degree", text: "Bachelor's Degree" },
        { value: "Master", text: "Master" },
        { value: "PhD", text: "PhD" },
        { value: "Professional Cert", text: "Professional Cert" },
      ],
      quaoptions: [
        { value: null, text: "Pilih Kelayakan anda", disabled: true },
        { value: "SPM", text: "SPM" },
        { value: "STPM", text: "STPM" },
        { value: "Skills Cert", text: "Sijil Kemahiran" },
        { value: "Diploma", text: "Diploma" },
        { value: "Bachelor's Degree", text: "Ijazah Sarjana Muda" },
        { value: "Master", text: "Ijazah Sarjana" },
        { value: "PhD", text: "PhD" },
        { value: "Professional Cert", text: "Sijil Profesional" },
      ],
      marital_statusoptions: [
        { value: null, text: "Choose Marital status", disabled: true },
        { value: "Single", text: `${this.$i18n.t(`Single`)}` },
        { value: "Married", text: `${this.$i18n.t(`Married`)}` },
        { value: "Divorced", text: `${this.$i18n.t(`Divorced`)}` },
        { value: "Widowed", text: `${this.$i18n.t(`Widowed`)}` },
      ],
      raceoptions: [
        { value: null, text: "Choose Race", disabled: true },
        { value: "Malay", text: `${this.$i18n.t(`Malay`)}` },
        { value: "Chinese", text: `${this.$i18n.t(`Chinese`)}` },
        { value: "Indian", text: `${this.$i18n.t(`Indian`)}` },
        { value: "Others", text: `${this.$i18n.t(`Others`)}` },
      ],
      isModal2Visible: false,
      allGrade: [],
      gradeList: [],
      businessUnitList: [],
      allbusinessUnit: [],
      departmentList: [],
      alldepartment: [],
      allBranch: [],
      branchList: [],
      positionList: [],
      allPosition: [],
      supervisorListName: [],
      allSvName: [],
      svNameList: [],
      historyList: [],
      currentPage: 1,
      perPage: 10,
      rows: null,
      fields: [
        {
          key: "index",
          label: "No.",
          thClass: "text-center",
        },
        {
          key: "date",
          label: "Transfer/ Promotion Date",
          thClass: "text-center",
        },
        {
          key: "previous_subsidiary_name",
          label: "Previous Company",
          thClass: "text-center",
        },
        {
          key: "prev_department_name",
          label: "Previous Department",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "prev_position",
          label: "Previous Position",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "subsidiary_name",
          label: "Promoted/Transferred Company",
          thClass: "text-center",
        },
        {
          key: "type",
          label: "Type",
          thClass: "text-center",
          dClass: "text-center",
        },
        {
          key: "action",
          label: "Action",
          tdClass: "text-center",
        },
      ],
      transferID: null,
      isHasEducation: false,
      defaultDate: "2022",
      DatePickerFormat: "yyyy",
    };
  },
  computed: {
    ...mapGetters(["roles", "userDetails"]),
    isEnglish() {
      return this.$i18n.locale === "en";
    },
    isDisabled() {
      // All role disable except Admin
      return this.roles.selectedRole !== 1;
    },
  },
  watch: {
    isNewLogo() {},
    // eslint-disable-next-line func-names
    "$i18n.locale": function () {
      this.genderoptions = [
        {
          value: null,
          text: `${this.$i18n.t(`Choose Gender`)}`,
          disabled: true,
        },
        { value: "Male", text: `${this.$i18n.t(`Male`)}` },
        { value: "Female", text: `${this.$i18n.t(`Female`)}` },
      ];
      this.marital_statusoptions = [
        { value: null, text: "Choose Marital status", disabled: true },
        { value: "Single", text: `${this.$i18n.t(`Single`)}` },
        { value: "Married", text: `${this.$i18n.t(`Married`)}` },
        { value: "Divorced", text: `${this.$i18n.t(`Divorced`)}` },
        { value: "Widowed", text: `${this.$i18n.t(`Widowed`)}` },
      ];
      this.raceoptions = [
        { value: null, text: "Choose Race", disabled: true },
        { value: "Malay", text: `${this.$i18n.t(`Malay`)}` },
        { value: "Chinese", text: `${this.$i18n.t(`Chinese`)}` },
        { value: "Indian", text: `${this.$i18n.t(`Indian`)}` },
        { value: "Others", text: `${this.$i18n.t(`Others`)}` },
      ];
    },
  },
  mounted() {
    this.getDetails();
    this.getEducation();
    this.checkLoggedUser();
    // this.getSubsidiaryStaff()
    this.getGrade();
    this.getSvName();
    this.getSubsidiary();
    this.getDepartment();
    this.getWorkLocation();
    this.getPosition();
    setTimeout(() => {
      this.$refs.simpleRules.reset();
    }, 500);
    // this.getHistory()
    // console.log(this.supervisor)
  },
  beforeMount() {},
  methods: {
    changeProfilePic() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        console.log(">> update profile pic here");

        if (this.id == undefined) {
          const data = new FormData();

          data.append("file", file);

          this.$axios
            .post(`${this.$baseUrl}/profile_picture/editProfilePicture`, data)
            .then((response) => {
              console.log(">> successful update image", response);
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t("Succesfully update profile picture!")}`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    titleColor: "#000",
                  },
                },
                {
                  position: "top-right",
                  type: "info",
                }
              );
              this.profile.image.url = response.data.data.url
            });
        }
      }
    },
    forceRerenderPersonal() {
      this.refreshPersonal += 1;
    },
    DateChanged(date) {
      const newDay = moment(date).format("D-MM-yyyy");
      return newDay;
    },
    onFileChangeEdu(e) {
      // console.log('on file change', e.target.files[0].size)
      if (e.target.files.length !== 0) {
        const fileSize = e.target.files[0].size / 1000;

        if (fileSize > 5120) {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t("File must less than 5 MB")}.`,
                icon: "XIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: "#e80202",
              },
            },
            {
              position: "top-right",
              type: "info",
            }
          );
        }
      }
    },
    getGrade() {
      this.$axios.get(`${this.$baseUrl}/grades/`).then((response) => {
        this.allGrade = response.data.data;
        this.allGrade.forEach((d) => {
          this.gradeList.push(d.grade);
        });
      });
    },
    getGradeSet() {
      this.$axios
        .get(
          `${this.$baseUrl}/grades/get_grade_set?grade=${this.profile.grade}`
        )
        .then((response) => {
          this.profile.grade_set = response.data.data.grade_set;
        });
    },

    showValue(a) {
      console.log("Show employee number:", a);
      if (a != null) {
        this.$axios
          .get(
            `${
              this.$baseUrl
            }/employees/getAllEmployeesNameWithoutPerformance?employee_number=${
              a.empNo !== undefined ? a.empNo : a
            }`
          )
          .then((response) => {
            const SVname = response.data.data;
            this.supervisorName = SVname[0].full_name;

            // console.log("Full Name:", response)
          })
          .catch(() => {
            // console.log("sini", error)
            this.supervisorName = "Please choose Supervisor";
          });
      } else {
        this.supervisor = [];
      }
    },
    getSvName() {
      this.$axios
        .get(
          `${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance?employee_number=${this.supervisor}&status=active`
        )
        .then((response) => {
          this.supervisorListName = response.data.data;
          this.supervisorListName.forEach((sv) => {
            this.svNameList.push({
              text: sv.full_name,
              value: sv.user_id,
              empNo: sv.employee_number,
            });
          });
        });
    },

    forceRerender() {
      this.refreshProfile += 1;
    },
    checkLoggedUser() {
      this.currentUserRole = this.roles.selectedRole;
    },
    repeateAgain() {
      this.items.push({
        qualification: "",
        institute: "",
        field_of_study: "",
        year_of_graduate: null,
        file: {},
      });
    },
    // getSubsidiaryStaff(){
    //   const params = new URLSearchParams()

    //   params.append('subsidiary_id', this.subId)

    //   this.$axios
    //     .get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance`, { params })
    //     .then(response => {
    //       this.staffList = response.data.data.map(item => ({
    //         text: item.user_id,
    //         value: item.full_name,
    //       }))
    //     })
    // },
    getSubsidiary() {
      this.$axios
        .get(`${this.$baseUrl}/subsidiary/getById?id=${this.subId}`)
        .then((response) => {
          this.allbusinessUnit = response.data.data;
          this.allbusinessUnit.forEach((b) => {
            this.businessUnitList.push({
              value: b.id,
              text: b.subsidiary_name,
            });
          });
        });
    },
    getDepartment() {
      this.$axios
        .get(
          `${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${this.subId}`
        )
        .then((response) => {
          const result = response.data;
          if (result.success) {
            this.alldepartment = response.data.data;

            this.alldepartment.forEach((d) => {
              this.departmentList.push({
                value: d.id,
                text: d.name,
              });
            });
          }
        });
    },
    getWorkLocation() {
      this.$axios
        .get(
          `${this.$baseUrl}/branch/get_branch_by_company?company_id=${this.subId}`
        )
        .then((response) => {
          this.allBranch = response.data.data;
          this.allBranch.forEach((d) => {
            this.branchList.push({
              value: d.id,
              text: d.name,
            });
          });
        });
    },
    getPosition() {
      this.$axios
        .get(`${this.$baseUrl}/position/get_position_all`)
        .then((response) => {
          this.allPosition = response.data.data;

          this.allPosition.forEach((d) => {
            this.positionList.push(d.name);
          });
        });
    },
    getDetails() {
      let id = null;
      if (this.id == undefined) {
        id = this.userDetails.user_id;
      } else {
        id = this.id;
      }

      this.$axios
        .get(`${this.$baseUrl}/supervisor/getEmployee?user_id=${id}`)
        .then((response) => {
          // console.log('data user', response)
          if (response.data.success === true) {
            const profileData = response.data.data;
            this.name = profileData.name !== null ? profileData.name : "-";
            this.email = profileData.email !== null ? profileData.email : "-";
            this.position =
              profileData.employee.position !== null
                ? profileData.employee.position
                : "-";
            this.mobile_number =
              profileData.employee.mobile_number !== null
                ? profileData.employee.mobile_number
                : "-";
            this.employee_number =
              profileData.employee.employee_number !== null
                ? profileData.employee.employee_number
                : "-";
            this.department =
              profileData.department !== null ? profileData.department : "-";
            this.branch = profileData.branch;
            this.district = profileData.district;
            this.supervisor = [];

            if (profileData.supervisor !== null) {
              this.supervisor.push({
                text: profileData.supervisor,
                value: profileData.employee.supervisor_id,
                empNo: profileData.sv_employee_number,
                // sv employee number
              });
            } else {
              this.supervisor.push({
                text: "-",
                value: 0,
                empNo: `${this.$t("Please assign a supervisor")}`,
              });
            }

            this.supervisorName = profileData.supervisor;
            this.hire_date =
              profileData.employee.hire_date !== null
                ? profileData.employee.hire_date
                : "-";
            // maklumat peribadi
            this.profile.email = profileData.email;
            this.profile.image = profileData.profile_picture.file;
            this.profile.full_name = profileData.employee.full_name;
            this.profile.first_name = profileData.employee.first_name;
            this.profile.middle_name = profileData.employee.middle_name;
            this.profile.last_name = profileData.employee.last_name;
            this.profile.mobile_number = profileData.employee.mobile_number;
            this.profile.ic = profileData.employee.ic;
            this.profile.dob = profileData.employee.dob;
            this.profile.address = profileData.employee.address;
            this.profile.address1 = profileData.employee.address_line1;
            this.profile.address2 = profileData.employee.address_line2;
            this.profile.address3 = profileData.employee.address_line3;
            this.profile.postal_code = profileData.employee.postal_code;
            this.profile.state = profileData.employee.state;
            this.profile.city = profileData.employee.city;
            this.profile.country = profileData.employee.country;
            // eslint-disable-next-line no-nested-ternary
            this.profile.gender = profileData.employee.gender;
            this.profile.religion = profileData.employee.religion;
            this.profile.race = profileData.employee.race;
            this.profile.nationality = profileData.employee.nationality;
            this.profile.marital_status = profileData.employee.marital_status;
            // organisasi
            this.profile.position = profileData.employee.position;
            this.profile.grade = profileData.employee.grade;
            this.profile.grade_set = profileData.employee.grade_set;
            this.profile.employee_number = profileData.employee.employee_number;
            this.profile.employee_status = profileData.employee.employee_status;
            this.profile.hire_date = profileData.employee.hire_date;
            this.profile.year_of_service = profileData.employee.year_of_service;
            this.profile.branch_id = profileData.employee.branch_id;
            this.profile.business_unit = profileData.employee.business_unit;
            // this.profile.supervisor_id = profileData.employee.supervisor.id
            this.profile.department_id = profileData.employee.department_id;
            this.forceRerenderPersonal();
            this.getHistory();
            setTimeout(() => {
              this.show = false;
            }, 500);
          }
          // else {
          //   // this.$errorConfirmation()
          //   this.errorConfirmation('danger')
          // }
        })
        .catch(() => {
          this.show = false;
        });
    },
    getHistory() {
      // console.log()
      const data = new FormData();

      data.append("employee_number", this.profile.employee_number);

      this.$axios
        .post(`${this.$baseUrl}/transfer_promotion/get_history`, data)
        .then((response) => {
          // console.log('res', response)
          if (response.data.data.length !== 0) {
            this.historyList = response.data.data;
            // this.historyList.forEach(list => {
            //   this.subsidiaryList.push(list.id)
            //   // console.log("subsidiary id ", list.id)
            // })
            // console.log(response)
            // console.log("subsidiary id list", this.subsidiaryList)
            this.subsidiaryList = this.historyList.map((list) => list.id);
          } else {
            this.historyList = [];
          }
          this.show = false;
          this.rows = this.historyList.length;
        })
        .catch(() => {
          this.show = false;
          this.historyList = [];
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t("No record retrieved")}.`,
                icon: "InfoIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: "#53b7db",
              },
            },
            {
              position: "top-right",
              type: "info",
            }
          );
        });
    },
    getEducation() {
      let id = null;
      if (this.id == undefined) {
        id = this.userDetails.user_id;
      } else {
        id = this.id;
      }
      const data = new FormData();
      data.append("user_id", id);
      this.$axios
        .post(`${this.$baseUrl}/admin/getEmployeeEducationByAll`, data)
        .then((response) => {
          this.isHasEducation = response.data.data.employee.educations === 0;
          // console.log(this.isHasEducation)
          if (response.data.data.employee.educations.length > 0) {
            for (
              let i = 0;
              i < response.data.data.employee.educations.length;
              i += 1
            ) {
              this.existitems.push({
                id: response.data.data.employee.educations[i].employee_id,
                qualification:
                  response.data.data.employee.educations[i].qualification,
                institute: response.data.data.employee.educations[i].institute,
                field_of_study:
                  response.data.data.employee.educations[i].field_of_study,
                year_of_graduate:
                  response.data.data.employee.educations[i].year_of_graduate,
                file: response.data.data.employee.educations[i].file,
                fileToUpload: null,
                eduId: response.data.data.employee.educations[i].id,
              });
            }
          } else {
            this.isEduInfo = false;
          }
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.isNewLogo = true;
      this.testData = true;
      // eslint-disable-next-line prefer-destructuring
      this.newLogo = this.$refs.companyPicture.files[0];
      this.newLogoTemp = URL.createObjectURL(file);
    },
    showModal2() {
      this.isModal2Visible = true;
    },
    closeModal2() {
      this.isModal2Visible = false;
    },
    createUser() {
      this.$refs.basicRules.validate().then((success) => {
        if (success) {
          const data = new FormData();
          data.append("user_id", this.id);
          data.append("first_name", this.profile.first_name);
          data.append("middle_name", this.profile.middle_name);
          data.append("last_name", this.profile.last_name);
          // data.append('full_name', this.profile.full_name)
          data.append("mobile_number", this.profile.mobile_number);
          data.append("ic", this.profile.ic);
          // const dob = this.profile.dob.split("-").reverse().join("-");
          // data.append("dob", dob);
          data.append("dob", this.profile.dob);
          data.append("gender", this.profile.gender);
          data.append("race", this.profile.race);
          data.append("address", this.profile.address);
          data.append("address_line1", this.profile.address1);
          data.append("address_line2", this.profile.address2);
          data.append("address_line3", this.profile.address3);
          data.append("postal_code", this.profile.postal_code);
          data.append("state", this.profile.state);
          data.append("city", this.profile.city);
          data.append("race", this.profile.race);
          data.append("religion", this.profile.religion);
          data.append("marital_status", this.profile.marital_status);
          data.append("country", this.profile.country);
          data.append("nationality", this.profile.nationality);
          data.append("email", this.profile.email);
          // data.forEach(a => {
          //   console.log('append', a)
          // })

          // this.$axios
          //   .post(`${this.$baseUrl}/users/update_employee_detail`, data)
          //   .then((response) => {
              // if (response.data.success === true){
              //   data.append('', this.profile.full_name)
              // } else {
              //   // this.$errorConfirmation()
              //   this.errorConfirmation('danger')
              // }
              // if (response) {
              //   const emailEmp = new FormData();
              //   emailEmp.append("user_id", this.id);
              //   emailEmp.append('email', this.profile.email)

              this.$axios
                .post(`${this.$baseUrl}/employees/updateEmployee`, data)
                .then((response) => {
                  console.log('success update', response)
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t("Profile info successfully saved")}.`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      titleColor: "#000",
                    },
                  },
                  {
                    position: "top-right",
                    type: "info",
                  }
                );
                this.getDetails();
                setTimeout(() => {}, 1000);
            })
            //   }
            // });
        }
      });
    },
    createOrganization() {
      // console.log("masukk", this.id, this.profile.grade, this.profile.employee_number, this.supervisor[0].value, this.profile.branch_id, this.profile.department_id, this.profile.business_unit)
      // console.log('supervisor', this.supervisor)
      this.$refs.orgRules.validate().then((success) => {
        if (success) {
          const data = new FormData();
          data.append("user_id", this.id);
          // data.append("position", this.profile.position)
          data.append("grade", this.profile.grade);
          // data.append("gred_set", this.profile.grade_set)
          data.append("employee_number", this.profile.employee_number);
          // data.append("employee_status", this.profile.employee_status)
          data.append(
            "supervisor_id",
            Array.isArray(this.supervisor)
              ? this.supervisor[0].value
              : this.supervisor.value
          );
          // const hiredate = this.profile.hire_date.split("-").reverse().join("-");
          // data.append("hire_date", this.profile.hire_date)
          // data.append('year_of_service', this.profile.year_of_service)
          data.append("branch_id", this.profile.branch_id);
          data.append("department_id", this.profile.department_id);
          data.append("business_unit", this.profile.business_unit);
          // data.forEach(k => {
          //   console.log('k', k)
          // })

          this.$axios
            .post(`${this.$baseUrl}/users/update_employee_detail`, data)
            .then((response) => {
              // if (response.data.success === true){
              //   data.append('', this.profile.full_name)
              // } else {
              //   // this.$errorConfirmation()
              //   this.errorConfirmation('danger')
              // }
              if (response) {
                setTimeout(() => {
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        title: `${this.$t(
                          "Organization info successfully saved"
                        )}.`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        titleColor: "#000",
                      },
                    },
                    {
                      position: "top-right",
                      type: "info",
                    }
                  );
                }, 1000);
                // this.supervisor = []
                this.getDetails();
              }
            })
            .catch(() => {
              // console.log(error.response)
            });
        }
      });
    },
    educationInfo() {
      const isObjectEmpty = Object.keys(this.items[0]).map((dt) => {
        // check semua value dalam object kalau ada yang empty
        if (!this.items[0][dt]?.length) {
          return false; // kalau semua ada value
        }
        return true; // kalau ada satu value yang kosong
      });
      if (!isObjectEmpty.find((el) => el === true)) {
        // kalau semua value empty
        this.updateEducation();
      } else {
        // kalau salah satu dah isi
        this.createEducation();
      }
    },
    createEducation() {
      this.$refs.validateEducation.validate().then((success) => {
        if (success) {
          const data = new FormData();
          data.append(`user_id`, this.id);
          data.append(`qualification`, this.items[0].qualification);
          data.append(`institute`, this.items[0].institute);
          data.append(`field_of_study`, this.items[0].field_of_study);
          data.append(
            `year_of_graduate`,
            this.items[0].year_of_graduate.toString().split(" ")[3]
          );
          data.append(`file`, this.items[0].fileToUpload);
          // }

          this.items[0] = {
            qualification: null,
            institute: null,
            field_of_study: "",
            year_of_graduate: null,
            fileToUpload: [],
          };
          // data.forEach(x => {
          //   console.log(x)
          // })
          this.$axios
            .post(`${this.$baseUrl}/admin/storeEmployeeEducationsByAdmin`, data)
            .then((response) => {
              if (response) {
                if (this.existitems.length !== 0) {
                  this.existitems = [];
                }
                this.getEducation();
                setTimeout(() => {
                  this.$refs.validateEducation.reset();
                }, 500);
                setTimeout(() => {
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        title: `${this.$t(
                          "Education info successfully added"
                        )}.`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        titleColor: "#000",
                      },
                    },
                    {
                      position: "top-right",
                      type: "info",
                    }
                  );
                }, 1000);
              }
            });
        }
      });
    },

    updateEducation() {
      this.$refs.validateEditEducation.validate().then((success) => {
        if (success) {
          const data = new FormData();
          this.existitems.forEach((item, index) => {
            const splitdate = item.year_of_graduate.toString().split(" ");
            const idd = item.eduId;
            data.append(`user_id`, this.id);
            data.append(`education_id[]`, idd);
            data.append(`qualification[${idd}]`, item.qualification);
            data.append(`institute[${idd}]`, item.institute);
            data.append(`field_of_study[${idd}]`, item.field_of_study);
            if (splitdate.length > 1) {
              data.append(`year_of_graduate[${idd}]`, splitdate[3]);
            } else {
              data.append(`year_of_graduate[${idd}]`, item.year_of_graduate);
            }
            if (item.fileToUpload !== null && item.fileToUpload !== undefined) {
              data.append(`file[${idd}]`, item.fileToUpload);
            }
            // data.forEach(x => {
            //   console.log('update', x)
            // })

            this.$axios
              .post(
                `${this.$baseUrl}/admin/editEmployeeEducationsByAdmin`,
                data
              )
              .then(() => {
                // data.forEach(x => {
                //   console.log('update', x)
                // })

                // if (eduSuccess.success){
                if (index === this.existitems.length - 1) {
                  if (this.existitems.length !== 0) {
                    this.existitems = [];
                    this.getEducation();
                  } else {
                    this.getEducation();
                  }
                  setTimeout(() => {
                    this.$toast(
                      {
                        component: SAJToast,
                        props: {
                          title: `${this.$t(
                            "Education info successfully updated"
                          )}.`,
                          // icon: "CheckCircleIcon",
                          fafont: "fa-solid fa-graduation-cap",
                          titleColor: "#000",
                        },
                      },
                      {
                        position: "top-right",
                        type: "info",
                        // toastClassName: "info-toast",
                      }
                    );
                  }, 1000);
                }
              });
          });
        }
      });
    },

    deleteEducation(deleteIdEdu) {
      const data = new FormData();

      data.append(`education_id`, deleteIdEdu);

      this.$axios
        .post(`${this.$baseUrl}/employeeEducations/delete`, data)
        .then(() => {
          if (this.existitems.length !== 0) {
            this.existitems = [];
            this.getEducation();
            this.hideAlertModal();
          }
          setTimeout(() => {
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t("Education info successfully delete")}.`,
                  icon: "Trash2Icon",
                  iconBg: "#e80202",
                  variant: "success",
                  titleColor: "#000",
                },
              },
              {
                position: "top-right",
                type: "info",
              }
            );
          }, 1000);
        });
    },

    showAlertModal(type, id) {
      switch (type) {
        case "edu-task":
          this.$bvModal.show("delete-edu");
          this.deleteIdEdu = id;
          break;

        default:
      }
    },

    hideAlertModal(type) {
      switch (type) {
        case "edu-task":
          this.$bvModal.hide("delete-edu");
          break;
        default:
          this.$bvModal.hide("delete-edu");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// .disable-flat-pickr {
//   background-color: gray !important;
// }
.custom-select:disabled {
  color: #6e6b7b !important;
}

// .dateRanhill .flatpickr-input[readonly], .flatpickr-input ~ .form-control[readonly], .flatpickr-human-friendly[readonly] {
//   background-color: #efefef !important;
// }
</style>
<style>
.my-picker-class {
  border: 1.1px solid #c4c4c4 !important;
  width: 100%;
  height: 2.75rem;
  border-radius: 5px;
  outline-color: #ff0000 !important;
  font-size: 1rem;
  font-family: Poppins;
  font-style: normal;
  color: black;
  font-weight: 400;
  text-align: center;
}

.custom-select:disabled {
  color: #000000 !important;
}
</style>
